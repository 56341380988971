import styled from 'styled-components';

import { space, size, layout, color, flex, text } from '@/styles/system';

export default styled.div`
  margin: 0;
  ${flex}
  ${space}
  ${size}
  ${layout}
  ${color}
  ${text}
`;
