import React, { useEffect, useContext, useRef } from 'react';
import { useSpring, animated } from 'react-spring';
import styled from 'styled-components';

import FooterRevealContext from '@/context/FooterRevealContext';
import { useTransitionStore } from './Provider';

import footerTransition from '@/components/Footer/transition';

import theme from '@/styles/theme';
import { scale, media } from '@/styles/utils';

const transformOriginDefault = 'center 110vh';
const transformOriginFooter = 'center calc(100% - 600px)';

const Container = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
`;

const View = styled(animated.div)`
  width: 100%;
  transform-origin: ${transformOriginDefault};
`;

const Overlay = styled(animated.div)`
  position: relative;
  width: 100%;
  margin-top: ${scale(1)};
  border-radius: ${scale(theme.page.borderRadius)}
    ${scale(theme.page.borderRadius)} 0 0;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);

  > div {
    height: calc(100vh - ${scale(1)});
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    transform-origin: ${transformOriginDefault};
    background: ${theme.color.white};
    will-change: transform;

    > div {
      padding-bottom: ${scale(6)};
      border-radius: ${scale(theme.page.borderRadius)}
        ${scale(theme.page.borderRadius)} 0 0;

      ${media.lg`
        padding-bottom: 0;
      `}
    }
  }
`;

export default ({ view, action, isOverlay }) => {
  const containerRef = useRef(null);
  const viewRef = useRef(null);
  const [
    { enter, usual, leave, hasEntered, isOverlayActive },
    dispatch,
  ] = useTransitionStore();
  const [, revealFooter] = useContext(FooterRevealContext);

  const [springProps, setSpring] = useSpring(() =>
    action === 'MOUNT'
      ? usual
      : {
          opacity: enter.opacity,
          transform: enter.transform,
        }
  );

  useEffect(() => {
    if (action === 'ENTER') {
      // a new view that is entering the stage
      setSpring({
        ...usual,
        config: enter.config,
        onStart: null,
        onRest: () => {
          dispatch({
            type: 'HAS_ENTERED',
            locationKey: view.props.location.key,
          });
        },
      });
    } else if (action === 'LEAVE') {
      // an existing view that has to leave the stage
      setSpring({
        ...leave,
        config: leave.config,
        onStart: null,
        onRest: null,
      });
    } else if (action === 'OVERLAY_LEAVE') {
      // an existing overlay that is being closed
      setSpring({
        ...enter,
        config: enter.config,
        onStart: null,
        onRest: () => {
          dispatch({
            type: 'HAS_ENTERED',
            locationKey: view.props.location.key,
          });
        },
      });
    } else if (action === 'OVERLAY_RESUME') {
      // the view that is beneath the overlay is resumed to
      setSpring({
        ...usual,
        config: leave.config,
        onStart: null,
        onRest: () => {
          dispatch({
            type: 'HAS_ENTERED',
            locationKey: view.props.location.key,
          });
        },
      });
    } else if (revealFooter) {
      // the transition happening when the footer gets revealed
      setSpring({
        ...footerTransition.reveal,
        onStart: () => {
          if (!viewRef.current) return;

          viewRef.current.style.transformOrigin = transformOriginFooter;
        },
        onRest: null,
      });
    } else if (action === 'MOUNT') {
      // the initial mount
      setSpring({
        ...usual,
        config: footerTransition.reveal.config,
        onStart: null,
        onRest: () => {
          if (!viewRef.current) return;

          viewRef.current.style.transformOrigin = transformOriginDefault;
        },
      });
    }
  }, [action, revealFooter]);

  useEffect(() => {
    if (hasEntered && !isOverlayActive) {
      containerRef.current.style.position = 'relative';
    }
  }, [hasEntered]);

  return (
    <Container ref={containerRef}>
      {isOverlay ? (
        <Overlay style={springProps}>
          <div>{view}</div>
        </Overlay>
      ) : (
        <View ref={viewRef} style={springProps}>
          {view}
        </View>
      )}
    </Container>
  );
};
