import styled from 'styled-components';

import Box from '@/atoms/Box';

import {
  pXLarge,
  pLarge,
  p,
  pSmall,
  h1,
  h2,
  h3,
  h4,
} from '@/styles/typography';
import { media } from '@/styles/utils';

export default styled(Box).attrs(props => ({
  as: props.as || 'p',
  t: props.t || 0,
  whiteSpace: props.whiteSpace || 'normal',
}))`
  ${props => props.t === -1 && pSmall}
  ${props => props.t === 0 && p}
  ${props => props.t === 1 && pLarge}
  ${props => props.t === 2 && pXLarge}
  ${props => props.t === 'h1' && h1}
  ${props => props.t === 'h2' && h2}
  ${props => props.t === 'h3' && h3}
  ${props => props.t === 'h4' && h4}

  hyphens: ${props => (props.hyphensMobile ? 'auto' : 'none')};
  white-space: ${props => props.whiteSpace};

  ${media.lg`
    hyphens: ${props => (props.hyphens ? 'auto' : 'none')};
  `}
`;
