import styled from 'styled-components';

import Box from '@/atoms/Box';

import theme from '@/styles/theme';
import { scale } from '@/styles/utils';

export default styled(Box).attrs({
  px: theme.grid.outerMargin,
})`
  position: relative;
  margin-left: auto;
  margin-right: auto;
  width: 100%;

  ${typeof theme.grid.maxWidth !== 'undefined' &&
    `
    max-width: ${scale(theme.grid.maxWidth)};
  `}
`;
