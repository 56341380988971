import styled from 'styled-components';

import Box from '@/atoms/Box';
import GridItem from './Item';
import GridContainer from './Container';

import theme from '@/styles/theme';

const Grid = styled(Box).attrs({
  mx: theme.grid.gutter.map(g => -g / 2),
})`
  position: relative;
  display: flex;
  flex-wrap: wrap;
`;

Grid.Item = GridItem;
Grid.Container = GridContainer;

export default Grid;
