import theme from '@/styles/theme';

export const forceArray = possibleArray =>
  Array.isArray(possibleArray) ? possibleArray : [possibleArray];

/* Get grid column width based on width "string" coming from Sanity
 */
export const getGridWidth = widthString => {
  const gridWidths = {
    w1_4: [1, 1, 1 / 4],
    w1_3: [1, 1, 1 / 3],
    w1_2: [1, 1, 1 / 2],
    w2_3: [1, 1, 2 / 3],
    w1_1: [1],
  };

  return gridWidths[`w${widthString}`];
};

/* Get size string for images based on grid and theme breakpoints
 */
const getImageSize = width => {
  return typeof width === 'number' ? `${Math.round(width * 100)}vw` : width;
};

export const getImageSizes = widths => {
  const w = forceArray(widths);
  const lastSize = w.slice(-1)[0];

  const smallerSizes = w.slice(0, w.length - 1);
  let breakpointQueries;

  if (smallerSizes.length > 0) {
    breakpointQueries = [
      smallerSizes.map(
        (width, i) =>
          `(max-width: ${theme.breakpoints[i + 1]}) ${getImageSize(width)}`
      ),
      getImageSize(lastSize),
    ];
  } else {
    breakpointQueries = [getImageSize(lastSize)];
  }

  return breakpointQueries.join(', ');
};
