import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { animated } from 'react-spring';

import Grid from '@/atoms/Grid';
import Box from '@/atoms/Box';
import Text from '@/atoms/Text';
import Button from '@/atoms/Button';
import Column from './Column';

import theme from '@/styles/theme';
import { scale } from '@/styles/utils';
import { getResponsiveStyles } from '@/styles/system';

// from page scale-transition
const PAGE_SCALE = 0.95;

const Content = styled(animated(Box))`
  z-index: 0;
  position: fixed;
  bottom: 0;
  left: ${((1 - PAGE_SCALE) * 100) / 2}%;
  height: ${theme.footer.height}px;
  width: ${PAGE_SCALE * 100}%;

  ${getResponsiveStyles(
    ['padding-left', 'padding-right'],
    theme.grid.gutter,
    g => scale(PAGE_SCALE * g),
    {}
  )}

  a {
    transition: all 0.1s ease-out;

    :hover,
    :focus {
      color: ${theme.color.gray.onBlack.medium};
    }
  }
`;

const LinkList = styled(Text).attrs({ as: 'ul' })`
  list-style: none;

  li {
    ${props =>
      props.layout === 'horizontal' &&
      `
      display: inline-block;
      padding-right: ${scale(1)};
    `}
  }

  a {
    transition: all 0.1s ease-out;

    :hover,
    :focus {
      color: ${props =>
        props.textColor === 'gray.onBlack.dark'
          ? theme.color.gray.onBlack.medium
          : theme.color.gray.onBlack.light};
    }
  }
`;

const today = new Date();

export default ({ data, ...props }) => (
  <Content
    displayFlex="flex"
    flexDirection="column"
    justifyContent="space-between"
    pt={[0.25, 0.25, 1.25, 1.75]}
    pb={[5.5, 5.5, 7, 2]}
    textColor="white"
    {...props}
  >
    <Grid
      justifyContent="space-between"
      flexDirection={['column', 'column', 'row']}
    >
      <Column title="Get in touch">
        <LinkList t={-1} textColor="white">
          {data.emailAddresses.map(({ email }) => (
            <li key={email}>
              <a href={`mailto:${email}`}>{email}</a>
            </li>
          ))}
        </LinkList>
      </Column>

      <Column title="Connect">
        <LinkList t={-1} textColor="white">
          {data.socialLinks.map(({ label, url }) => (
            <li key={url}>
              <a href={url} target="_blank" rel="noopener noreferrer">
                {label}
              </a>
            </li>
          ))}
        </LinkList>
      </Column>

      <Column
        w={[1, 1, 0.5]}
        title="Now part of Forte Digital"
        displayFlex={['none', 'none', 'block']}
      >
        <Box displayFlex="flex">
          <Text
            t={-1}
            whiteSpace="pre-line"
            mr={[0.75, 0.75, 1, 1.5]}
            maxWidth="400px"
          >
            Pizza Pizza is now part of Forte Digital – an international digital
            consultancy focusing on the effective trifactor of technology,
            strategy and design.
          </Text>

          <Box flex="0 0 auto">
            <Button
              size="small"
              color="inverted"
              as="a"
              href="https://fortedigital.de"
              target="_blank"
              rel="noopener noreferrer"
            >
              Learn more →
            </Button>
          </Box>
        </Box>
      </Column>
    </Grid>

    <div>
      <Text
        t={-1}
        textColor="gray.onBlack.dark"
        textAlign={['center', 'center', 'center', 'left']}
        pb={0.375}
        displayFlex={['block', 'block', 'block', 'block', 'none']}
      >
        Typeface by{' '}
        <a
          href="https://www.abcdinamo.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Dinamo
        </a>
      </Text>

      <Text
        t={-1}
        textColor="gray.onBlack.dark"
        textAlign={['center', 'center', 'center', 'left']}
        pb={0.375}
        displayFlex={['none', 'none', 'none', 'none', 'block']}
      >
        Typeface provided by our friends at{' '}
        <a
          href="https://www.abcdinamo.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Dinamo
        </a>
      </Text>

      <LinkList
        t={-1}
        layout="horizontal"
        textColor="gray.onBlack.dark"
        textAlign={['center', 'center', 'center', 'left']}
      >
        <li>© {today.getFullYear()}</li>

        {data.legalLinks.map(({ title, slug }) => (
          <li key={slug.current}>
            <Link to={`/${slug.current}`}>{title}</Link>
          </li>
        ))}
      </LinkList>
    </div>
  </Content>
);
