import { css } from 'styled-components';

import theme from '@/styles/theme';

/**
 * media breakpoints for widths and aspect ratio
 */
export const media = theme.breakpoints.reduce((acc, size, i) => {
  acc[theme.breakpointNames[i + 1]] = (...args) => css`
    @media (min-width: ${size}) {
      ${css(...args)};
    }
  `;

  acc[`below${theme.breakpointNames[i]}`] = (...args) => css`
    @media (max-width: ${size}) {
      ${css(...args)};
    }
  `;

  return acc;
}, {});

/**
 * px to rem conversion
 */
export const rem = val => `${val / 16}rem`;

/**
 * scale measurements
 */
export const scale = value => `${value * theme.scale}rem`;

/**
 * visually hide element
 */
export const visuallyHidden = css`
  position: absolute;
  margin: -1px;
  border: 0;
  padding: 0;
  height: 1px;
  width: 1px;
  clip: rect(0 0 0 0);
  overflow: hidden;
`;

/**
 * striped background
 */
export const stripedBackground = (
  height,
  color,
  direction = 'to bottom'
) => css`
  background: linear-gradient(
    ${direction},
    ${color},
    ${color} 1px,
    transparent 1px,
    transparent
  );
  background-size: 100% ${height};
`;
