import React from 'react';
import { navigate } from '@reach/router';
import styled from 'styled-components';

import Text from '@/atoms/Text';

import theme from '@/styles/theme';
import { media, scale } from '@/styles/utils';

const CloseButton = styled(Text).attrs({ as: 'button', t: 0 })`
  border: 0;
  padding: 0 ${scale(0.75)};
  height: ${scale(2.25)};
  background: none;
  cursor: pointer;
  transition: color 0.1s ease-in-out;
  user-select: none;
  outline: none;
  color: ${theme.color.black};
  -webkit-tap-highlight-color: transparent;

  ${media.md`
    padding: 0 ${scale(1)};
    height: ${scale(2.5)};
  `};

  :hover,
  :focus,
  :active {
    color: ${theme.color.gray.onWhite.dark};
  }
`;

export default props => (
  <CloseButton onClick={() => navigate(-1)} {...props}>
    Close
  </CloseButton>
);
