import { scale } from '@/styles/utils';
import theme from '@/styles/theme';

// converts value in Array holding value
const forceArray = possibleArray =>
  Array.isArray(possibleArray) ? possibleArray : [possibleArray];

// gets object with styles for a specific prop
const getStyleForProp = (cssProps, value, conversion) => {
  const styles = forceArray(cssProps);

  return styles.reduce(
    (acc, cur) => ({
      ...acc,
      [cur]: conversion ? conversion(value) : value,
    }),
    {}
  );
};

export const getResponsiveStyles = (
  cssProps,
  values,
  conversion,
  base = {}
) => {
  const responsiveValues = forceArray(values);

  let styles = {
    ...base,
    ...getStyleForProp(cssProps, responsiveValues[0], conversion),
  };

  if (responsiveValues.length > 1) {
    styles = responsiveValues.slice(1).reduce((accStyles, curValue, i) => {
      const media = `@media screen and (min-width: ${theme.breakpoints[i]})`;

      return {
        ...accStyles,
        [media]: {
          ...accStyles[media],
          ...getStyleForProp(cssProps, curValue, conversion),
        },
      };
    }, styles);
  }

  return styles;
};

// applies props relevant to props definition
const applyProps = (props, propsDef, conversion = null) =>
  Object.keys(props).reduce((acc, prop) => {
    if (typeof propsDef[prop] !== 'undefined') {
      return getResponsiveStyles(propsDef[prop], props[prop], conversion, acc);
    }

    return acc;
  }, {});

/**
 * Spacing
 */
const spaceProps = {
  m: 'margin',
  mt: 'margin-top',
  mr: 'margin-right',
  mb: 'margin-bottom',
  ml: 'margin-left',
  mx: ['margin-left', 'margin-right'],
  my: ['margin-top', 'margin-bottom'],
  p: 'padding',
  pt: 'padding-top',
  pr: 'padding-right',
  pb: 'padding-bottom',
  pl: 'padding-left',
  px: ['padding-left', 'padding-right'],
  py: ['padding-top', 'padding-bottom'],
};

const spaceConversion = value => {
  if (typeof value === 'number') {
    return scale(value);
  }

  return value;
};

export const space = props => applyProps(props, spaceProps, spaceConversion);

/**
 * Size
 */
const sizeProps = {
  w: 'width',
  ht: 'height',
};

const sizeConversion = value => {
  if (typeof value === 'number') {
    return `${value * 100}%`;
  }

  return value;
};

export const size = props => applyProps(props, sizeProps, sizeConversion);

/**
 * Layout
 */
const layoutProps = {
  position: 'position',
  maxWidth: 'max-width',
  borderRadius: 'border-radius',
};

const layoutConversion = value => {
  if (typeof value === 'number') {
    return scale(value);
  }

  return value;
};

export const layout = props => applyProps(props, layoutProps, layoutConversion);

/**
 * Color
 */
const colorProps = {
  textColor: 'color',
  backgroundColor: 'background-color',
};

const colorConversion = value => {
  // handle hex & rgb(a)
  if (value.indexOf('#') > -1 || value.indexOf('rgb') > -1) {
    return value;
  }

  // handle dot-notation "gray.onRed.light"
  if (value && value.indexOf('.') > 0) {
    return value.split('.').reduce((acc, cur) => acc[cur], theme.color);
  }

  return theme.color[value];
};

export const color = props => applyProps(props, colorProps, colorConversion);

/**
 * Flex
 */
const flexProps = {
  displayFlex: 'display',
  alignItems: 'align-items',
  alignContent: 'align-content',
  justifyContent: 'justify-content',
  flexWrap: 'flex-wrap',
  flexBasis: 'flex-basis',
  flexDirection: 'flex-direction',
  flex: 'flex',
  justifySelf: 'justify-self',
  alignSelf: 'align-self',
  flexOrder: 'order',
};

export const flex = props => applyProps(props, flexProps);

/**
 * Text
 */
const textProps = {
  textAlign: 'text-align',
};

export const text = props => applyProps(props, textProps);
