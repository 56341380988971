import React, { useRef, useState, useEffect, useMemo } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';

import { useTransitionStore } from '@/components/PageTransition/Provider';

import Pill from './Pill';
import NavItem from './NavItem';

import { scale } from '@/styles/utils';

const Nav = styled.nav`
  display: inline-flex;
  padding: ${scale(0.25)} ${scale(0.3125)};

  ul {
    list-style: none;
    display: inline-flex;
    flex-wrap: nowrap;
  }
`;

const isActive = (itemPath, locationPath) =>
  itemPath === '/' ? locationPath === '/' : locationPath.startsWith(itemPath);

export default props => {
  const containerRef = useRef(null);
  const [{ currentLocation, isTransitioningToOverlay }] = useTransitionStore();
  const [activeItem, setActiveItem] = useState(-1);

  const { data } = useStaticQuery(graphql`
    query {
      data: allSanitySiteSettings {
        edges {
          node {
            homePage {
              id
            }
            mainNavigationItems {
              id
              title
              slug {
                current
              }
            }
          }
        }
      }
    }
  `);
  const navItemRefs = Array(data.edges[0].node.mainNavigationItems.length)
    .fill(1)
    .map(() => useRef(null));

  const navItems = useMemo(
    () =>
      data.edges[0].node.mainNavigationItems.map(({ id, slug, ...item }, i) => {
        const path =
          id === data.edges[0].node.homePage.id ? '/' : `/${slug.current}`;

        return {
          path,
          innerRef: navItemRefs[i],
          ...item,
        };
      }),
    [data]
  );

  useEffect(() => {
    if (currentLocation.pathname && !isTransitioningToOverlay) {
      setActiveItem(
        navItems.findIndex(item =>
          isActive(item.path, currentLocation.pathname)
        )
      );
    }
  }, [navItems, currentLocation.pathname]);

  return (
    <Nav role="navigation" ref={containerRef} {...props}>
      <ul>
        {navItems.map((item, i) => (
          <NavItem key={item.path} isActive={i === activeItem} {...item} />
        ))}
      </ul>

      <Pill
        containerRef={containerRef}
        activeNavItemRef={
          navItems[activeItem] ? navItems[activeItem].innerRef : null
        }
      />
    </Nav>
  );
};
