import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useSpring, animated } from 'react-spring';

import useDebouncedResize from '@/hooks/useDebouncedResize';
import { useTransitionStore } from '@/components/PageTransition/Provider';

import Bar from './Bar';
import CloseButton from './CloseButton';
import MainNavigation from './MainNavigation';

import { media, scale } from '@/styles/utils';

const Inner = styled(animated.div)`
  display: inline-flex;
  flex-direction: column;
`;

const InnerItem = styled(animated.div)`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${scale(2.75)};

  ${media.md`
    height: ${scale(3)};
  `};
`;

export default props => {
  const [{ isTransitioningToOverlay }] = useTransitionStore();

  const [springProps, setSpring] = useSpring(() => ({
    barClip: `inset(0px 0px 0px 0px round ${scale(1.5)})`,
    innerTransform: 'translateY(-50%)',
    config: { velocity: 14, mass: 1, tension: 180, friction: 26, clamp: true },
  }));

  const setStyles = () => {
    setSpring({
      barClip: isTransitioningToOverlay
        ? `inset(0px 100px 0px 100px round ${scale(1.5)})`
        : `inset(0px 0px 0px 0px round ${scale(1.5)})`,
      innerTransform: `translateY(${isTransitioningToOverlay ? '0%' : '-50%'})`,
    });
  };

  useEffect(setStyles, [isTransitioningToOverlay]);
  useDebouncedResize(() => setStyles());

  return (
    <Bar
      style={{
        WebkitClipPath: springProps.barClip,
        clipPath: springProps.barClip,
      }}
      {...props}
    >
      <Inner
        style={{
          transform: springProps.innerTransform,
        }}
      >
        <InnerItem>
          <CloseButton />
        </InnerItem>

        <InnerItem>
          <MainNavigation />
        </InnerItem>
      </Inner>
    </Bar>
  );
};
