import React, { useContext } from 'react';
import styled from 'styled-components';
import { useSpring } from 'react-spring';
import { useStaticQuery, graphql } from 'gatsby';

import FooterRevealContext from '@/context/FooterRevealContext';

import Content from './Content';

import theme from '@/styles/theme';
import transition from './transition';

const Footer = styled.footer`
  height: ${theme.footer.height}px;
`;

export default props => {
  const [footerRef, revealFooter] = useContext(FooterRevealContext);

  const { data } = useStaticQuery(graphql`
    query {
      data: allSanitySiteSettings {
        edges {
          node {
            addressDesignServices
            addressVentures
            emailAddresses {
              email
            }
            socialLinks {
              label
              url
            }
            legalLinks {
              title
              slug {
                current
              }
            }
          }
        }
      }
    }
  `);

  const springProps = useSpring({
    opacity: revealFooter ? 1 : 0,
    config: transition.reveal.config,
  });

  return (
    <Footer id="footer" ref={footerRef} {...props}>
      <Content data={data.edges[0].node} style={springProps} />
    </Footer>
  );
};
