import styled from 'styled-components';

import Text from '@/atoms/Text';

import theme from '@/styles/theme';
import { media } from '@/styles/utils';

const SIZE = {
  small: {
    t: 0,
    px: 1,
    py: 0.5,
    borderRadius: 6,
  },
  medium: {
    t: 1,
    px: 2,
    py: 0.5,
    borderRadius: 6,
  },
  large: {
    t: 2,
    px: 4,
    py: 1,
    borderRadius: 6,
  },
};

const COLOR = {
  default: {
    text: theme.color.white,
    textHover: theme.color.gray.onBlack.lightest,
    textActive: theme.color.gray.onBlack.medium,
    background: theme.color.black,
  },
  inverted: {
    text: theme.color.black,
    textHover: theme.color.gray.onWhite.dark,
    textActive: theme.color.gray.onWhite.medium,
    background: theme.color.white,
  },
};

export default styled(Text).attrs(props => ({
  as: props.as || 'button',
  t: props.size ? SIZE[props.size].t : SIZE.large.t,
  px: props.size ? SIZE[props.size].px : SIZE.large.px,
  py: props.size ? SIZE[props.size].py : SIZE.large.py,
  borderRadius: props.size
    ? SIZE[props.size].borderRadius
    : SIZE.large.borderRadius,
  textAlign: 'center',
}))`
  display: inline-block;
  width: ${props => (props.size === 'medium' ? 'auto' : '100%')};
  border: none;
  cursor: ${props => (!props.disabled ? 'pointer' : 'default')};
  outline: none;
  background: ${props =>
    props.color ? COLOR[props.color].background : COLOR.default.background};
  transition: all 0.15s ease-out;

  && {
    color: ${props =>
      props.color ? COLOR[props.color].text : COLOR.default.text};

    :hover,
    :focus {
      color: ${props =>
        props.color ? COLOR[props.color].textHover : COLOR.default.textHover};
    }

    :active {
      color: ${props =>
        props.color ? COLOR[props.color].textActive : COLOR.default.textActive};
    }
  }

  ${media.sm`
    width: auto;
  `};
`;
