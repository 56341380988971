export default {
  reveal: {
    opacity: 0.8,
    transform: 'translate3d(0,0vh,0) scale(0.95)',
    config: {
      mass: 1,
      tension: 280,
      friction: 20,
      clamp: true,
    },
  },
};
