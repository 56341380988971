import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';

import Masks from './SvgMasks';
import BackgroundImage from '@/components/BackgroundImage';

import {
  getHolderPaddingX,
  getHolderPaddingY,
  getFrameBorderRadiusX,
  getFrameBorderRadiusY,
} from './utils';
import { media, scale } from '@/styles/utils';

const FRAMES_WITH_MAXWIDTH = ['desktop', 'iphonex'];

const FRAME_ASPECT_RATIOS = {
  iphonex: 650 / 300,
};

const FLEX_ALIGNMENTS = {
  center: 'center',
  top: 'flex-start',
  bottom: 'flex-end',
  left: 'flex-start',
  right: 'flex-end',
};

const Asset = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  justify-content: ${props => FLEX_ALIGNMENTS[props.alignmentX]};
  align-items: ${props => FLEX_ALIGNMENTS[props.alignmentY]};
  background: ${props => props.backgroundColor};

  ${props =>
    props.backgroundColor &&
    `
    overflow: hidden;
  `}

  ${props =>
    props.paddingX &&
    getHolderPaddingX(props.alignmentX, 0, props.paddingX, props.frame)}
  ${props =>
    props.paddingY &&
    getHolderPaddingY(props.alignmentY, 0, props.paddingY, props.frame)}

  ${media.sm`
    ${props =>
      props.paddingX &&
      getHolderPaddingX(props.alignmentX, 1, props.paddingX, props.frame)}
    ${props =>
      props.paddingY &&
      getHolderPaddingY(props.alignmentY, 1, props.paddingY, props.frame)}
  `}

  ${media.md`
    ${props =>
      props.paddingX &&
      getHolderPaddingX(props.alignmentX, 2, props.paddingX, props.frame)}
    ${props =>
      props.paddingY &&
      getHolderPaddingY(props.alignmentY, 2, props.paddingY, props.frame)}
  `}

  ${media.lg`
    ${props =>
      props.paddingX &&
      getHolderPaddingX(props.alignmentX, 3, props.paddingX, props.frame)}
    ${props =>
      props.paddingY &&
      getHolderPaddingY(props.alignmentY, 3, props.paddingY, props.frame)}
  `}

  /* Img or Video directly, or FrameWrapper */
  & > div {
    width: 100%;
    max-width: ${props => (props.maxWidth ? `${props.maxWidth}px` : 'none')};

    ${props =>
      !props.frame &&
      `
      height: 100%;
    `}

    ${props =>
      props.frame === 'iphonex' &&
      `
      max-width: ${props.maxWidth ? `${props.maxWidth * 0.8}px` : 'none'};
    `}

    ${media.lg`
      max-width: ${props => (props.maxWidth ? `${props.maxWidth}px` : 'none')};
    `}
  }
`;

const FrameWrapper = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: ${props => props.assetAspectRatio * 100}%;
  filter: drop-shadow(0px 5px 20px rgba(0, 0, 0, 0.06))
    drop-shadow(0px 2px 5px rgba(0, 0, 0, 0.06));

  & > div {
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100%;
    max-width: ${props => (props.maxWidth ? `${props.maxWidth}px` : 'none')};

    ${props =>
      props.frame === 'iphonex' &&
      `
      max-width: ${props.maxWidth ? `${props.maxWidth * 0.8}px` : 'none'};
      clip-path: url(#clip-iphonex);
    `}

    ${props =>
      props.frame === 'desktop' &&
      `
      border-radius: ${scale(0.375)};
      box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.02), 0px 2px 5px rgba(0, 0, 0, 0.02);
      overflow: hidden;
      transform: translateZ(0);
      ${getFrameBorderRadiusX(props.alignmentX)};
      ${getFrameBorderRadiusY(props.alignmentY)};
    `}

    ${media.md`
      ${props =>
        props.frame === 'desktop' &&
        `
        border-radius: ${scale(0.75)};
        ${getFrameBorderRadiusX(props.alignmentX)};
        ${getFrameBorderRadiusY(props.alignmentY)};
      `}
    `}

    ${media.lg`
      max-width: ${props => (props.maxWidth ? `${props.maxWidth}px` : 'none')};
    `}

    & > div {
      ${props =>
        props.frameAspectRatio &&
        `
        height: 0;
        padding-bottom: ${props.frameAspectRatio * 100}% !important;
      `}
    }

    img {
      object-fit: contain !important;
      object-position: ${props => props.assetObjectPositionY} center !important;
    }
  }
`;

export default ({
  dimensions: { width, height },
  frame,
  maxWidth,
  alignmentX,
  alignmentY,
  background,
  backgroundImage,
  children,
  ...props
}) => {
  const backgroundColor = background ? background.hex : null;

  let assetProps = {
    alignmentX: alignmentX || 'center',
    alignmentY: alignmentY || 'center',
    paddingX: null,
    paddingY: null,
    frame,
  };

  // set max width
  if (maxWidth) {
    assetProps = {
      ...assetProps,
      maxWidth: width / 2,
    };
  }

  // set props for asset with frame
  if (frame) {
    assetProps = {
      ...assetProps,
      assetAspectRatio: height / width,
    };

    // set max width to asset dimensions depending on frame
    if (FRAMES_WITH_MAXWIDTH.includes(frame)) {
      assetProps = {
        ...assetProps,
        maxWidth: width / 2,
      };
    }

    // set fixed aspect ratio and object position depending on frame & alignment
    if (Object.keys(FRAME_ASPECT_RATIOS).includes(frame)) {
      assetProps = {
        ...assetProps,
        frameAspectRatio: FRAME_ASPECT_RATIOS[frame],
        assetObjectPositionY:
          assetProps.alignmentY === 'top' ? 'bottom' : 'top',
      };
    }
  }

  // padding x + y
  if (frame) {
    assetProps = {
      ...assetProps,
      paddingX: 'frame',
      paddingY: 'frame',
    };
  }

  if (backgroundColor) {
    assetProps = {
      ...assetProps,
      paddingX: 'backgroundColor',
      paddingY: 'backgroundColor',
    };
  }

  return (
    <Asset backgroundColor={backgroundColor} {...assetProps} {...props}>
      {backgroundImage && (
        <BackgroundImage
          image={backgroundImage}
          columnWidth={assetProps.columnWidth || 1}
        />
      )}

      {frame ? (
        <div>
          <FrameWrapper frame={frame} {...assetProps}>
            {children}
          </FrameWrapper>
        </div>
      ) : (
        children
      )}
    </Asset>
  );
};

export const query = graphql`
  fragment assetSettingsData on SanityAssetSettings {
    frame
    maxWidth
    alignmentX
    alignmentY
    background {
      hex
    }
    backgroundImage {
      ...backgroundImageData
    }
  }
`;

export const SvgMasks = Masks;
