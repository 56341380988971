import { css } from 'styled-components';

import { scale } from '@/styles/utils';

export const getHolderPaddingX = (alignX, breakpoint = 0, padding, frame) => {
  // values for 'center'
  let left = [2, 2, 2, 4];
  let right = [2, 2, 2, 4];

  if (padding === 'frame' && frame === 'iphonex') {
    left = [2, 2, 1, 1];
    right = [2, 2, 1, 1];
  }

  if (padding === 'backgroundColor' && frame === 'desktop') {
    left = [1, 1, 2, 4];
    right = [1, 1, 2, 4];
  }

  if (padding === 'frame' && frame === 'desktop') {
    left = [1, 1, 1, 1];
    right = [1, 1, 1, 1];
  }

  if (alignX === 'left') {
    left = [0, 0, 0, 0];
    right = [1.5, 1.5, 4, 8];
  }

  if (alignX === 'right') {
    right = [0, 0, 0, 0];
    left = [1.5, 1.5, 4, 8];
  }

  return css`
    padding-left: ${scale(left[breakpoint])};
    padding-right: ${scale(right[breakpoint])};
  `;
};

export const getHolderPaddingY = (alignY, breakpoint = 0) => {
  // values for 'center'
  let top = [2, 2, 2, 4];
  let bottom = [2, 2, 2, 4];

  if (alignY === 'bottom') {
    top = [2, 2, 4, 8];
    bottom = [0, 0, 0, 0];
  }

  if (alignY === 'top') {
    bottom = [2, 2, 4, 8];
    top = [0, 0, 0, 0];
  }

  return css`
    padding-top: ${scale(top[breakpoint])};
    padding-bottom: ${scale(bottom[breakpoint])};
  `;
};

export const getFrameBorderRadiusX = alignX => {
  if (alignX === 'left') {
    return css`
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    `;
  }
  if (alignX === 'right') {
    return css`
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    `;
  }

  return null;
};

export const getFrameBorderRadiusY = alignY => {
  if (alignY === 'top') {
    return css`
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    `;
  }
  if (alignY === 'bottom') {
    return css`
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    `;
  }

  return null;
};
