import React from 'react';

export default () => (
  <svg height="0" width="0">
    <defs>
      <clipPath id="clip-iphonex" clipPathUnits="objectBoundingBox">
        <path
          d="M0 0.0430769C0 0.0192862 0.0417867 0 0.0933333 0H0.210993C0.216868 0 0.221631 0.00219827 0.221631 0.00490998V0.00490998V0.00490998C0.221631 0.02344 0.254178 0.0384615 0.294326 0.0384615H0.5H0.705674C0.745822 0.0384615 0.778369 0.02344 0.778369 0.00490998V0.00490998V0.00490998C0.778369 0.00219827 0.783132 0 0.789007 0H0.906667C0.958213 0 1 0.0192862 1 0.0430769V0.956923C1 0.980714 0.958213 1 0.906667 1H0.0933333C0.0417867 1 0 0.980714 0 0.956923V0.0430769Z"
          fill="#000"
        />
      </clipPath>
    </defs>
  </svg>
);
