import React, { useEffect, useState } from 'react';
import { useSpring, animated } from 'react-spring';
import styled from 'styled-components';

import useDebouncedResize from '@/hooks/useDebouncedResize';

import { media, scale } from '@/styles/utils';
import theme from '@/styles/theme';

const Pill = styled(animated.div)`
  z-index: -1;
  position: absolute;
  left: 0;
  top: ${scale(0.25)};
  width: 100px;
  height: ${scale(2.25)};
  border-radius: ${scale(1.125)};
  background: ${theme.color.black};

  ${media.md`
    padding: 0 ${scale(1)};
    height: ${scale(2.5)};
    border-radius: ${scale(1.25)};
  `}
`;

export default ({ containerRef, activeNavItemRef, ...props }) => {
  // navbar is only active if we're on a page that's part of the main nav
  const [isActive, setActive] = useState(false);

  const [springProps, setSpring] = useSpring(() => ({
    transform: 'translateX(0px)',
    width: '0px',
    opacity: 0,
    config: { velocity: 14, mass: 1, tension: 180, friction: 26, clamp: true },
    immediate: true,
  }));

  const setTransition = () => {
    if (!containerRef) return;

    const target =
      activeNavItemRef && activeNavItemRef.current.getBoundingClientRect();
    const container =
      containerRef && containerRef.current.getBoundingClientRect();

    if (isActive && !activeNavItemRef) {
      // we're opening a page that's not part of the main nav
      setSpring({
        opacity: 0,
        immediate: true,
        onRest: () => setActive(false),
      });
    } else if (!isActive && activeNavItemRef) {
      // we're coming from a page that's not part of the main nav but opening a page that is
      setSpring({
        transform: `translateX(${target.left - container.left}px)`,
        width: `${target.width}px`,
        opacity: 1,
        immediate: true,
        onRest: () => {
          setActive(true);
        },
      });
    } else if (isActive && activeNavItemRef) {
      // we're switching from one page of the main nav to another
      setSpring({
        transform: `translateX(${target.left - container.left}px)`,
        width: `${target.width}px`,
        immediate: false,
        opacity: 1,
        onRest: () => {
          setActive(true);
        },
      });
    }
  };

  useEffect(() => setTransition(), [activeNavItemRef]);
  useDebouncedResize(() => setTransition());

  return <Pill style={springProps} {...props} />;
};
