import React from 'react';

import Grid from '@/atoms/Grid';
import Text from '@/atoms/Text';

export default ({ title, children, ...props }) => (
  <Grid.Item my={0.75} textAlign={['center', 'center', 'left']} {...props}>
    <Text as="h5" t={-1} textColor="gray.onBlack.dark">
      {title}
    </Text>

    {children}
  </Grid.Item>
);
