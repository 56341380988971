import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Helmet from 'react-helmet';

import fonts from '@/styles/fonts';

export default ({
  title,
  meta = { robotsIndex: true, metaDescription: null, ogImage: null },
  ...props
}) => {
  const { data } = useStaticQuery(graphql`
    query {
      data: allSanitySiteSettings {
        edges {
          node {
            metaTitle
            metaDescription
            ogImage {
              asset {
                url
              }
            }
          }
        }
      }
    }
  `);
  const defaultMeta = data.edges[0].node;

  const indexSafe =
    process.env.GATSBY_STAGE !== 'production'
      ? 'noindex,nofollow'
      : meta.robotsIndex
      ? 'index,follow'
      : 'noindex,follow';

  return (
    <Helmet
      titleTemplate={`%s — ${defaultMeta.metaTitle}`}
      defaultTitle={defaultMeta.metaTitle}
      title={title}
      {...props}
    >
      <html lang="en" />

      <style type="text/css">{fonts}</style>

      <meta name="robots" content={indexSafe} />

      <meta
        name="description"
        content={meta.metaDescription || defaultMeta.metaDescription}
      />
      <meta
        property="og:image"
        content={
          meta.ogImage ? meta.ogImage.asset.url : defaultMeta.ogImage.asset.url
        }
      />
    </Helmet>
  );
};

export const query = graphql`
  fragment metaData on SanityMeta {
    robotsIndex
    metaDescription
    ogImage {
      asset {
        url
      }
    }
  }
`;
