import React, { createContext } from 'react';
import { useInView } from 'react-intersection-observer';

import theme from '@/styles/theme';

const FooterRevealContext = createContext(null);

export const FooterRevealContextProvider = props => {
  const footerRevealWaypoint = (theme.footer.height * 7) / 8;

  const [inViewRef, inView] = useInView({
    rootMargin: `0px 0px -${footerRevealWaypoint}px 0px`,
  });

  return (
    <FooterRevealContext.Provider value={[inViewRef, inView]} {...props} />
  );
};

export default FooterRevealContext;
