export default {
  scale: 1, // (in rem) => scale(1) = 16px
  breakpoints: ['42em', '44em', '62em', '75em'],
  breakpointNames: ['xs', 'sm', 'md', 'lg', 'xl'],

  grid: {
    gutter: [0.75, 0.75, 1, 2.5],
    outerMargin: [0.75, 0.75, 1, 2.5],
    // maxWidth: 100,
    maxTextWidth: '960px',
    maxTextWidthSmall: '480px',
  },

  spacing: {
    xlarge: [10, 10, 10, 15],
    large: [7.5, 7.5, 7.5, 10],
    medium: [3, 3, 3, 5],
    small: [2.5],
    xsmall: [1.5, 1.5, 1.5, 2],
    gutter: [2, 2, 1, 2.5],
  },

  color: {
    white: '#fff',
    black: '#000',
    offBlack: '#1d1d1d',
    red: '#e8001c',
    whiteTransparent: 'rgba(255, 255, 255, 0.98)',
    gray: {
      onWhite: {
        lightest: 'rgba(0, 0, 0, 0.03)',
        light: 'rgba(0, 0, 0, 0.12)',
        medium: 'rgba(0, 0, 0, 0.45)',
        dark: 'rgba(0, 0, 0, 0.65)',
      },
      onGray: {
        light: 'rgba(0, 0, 0, 0.12)',
        medium: 'rgba(0, 0, 0, 0.45)',
      },
      onRed: {
        lightest: 'rgba(0, 0, 0, 0.05)',
        light: 'rgba(0, 0, 0, 0.12)',
        medium: 'rgba(0, 0, 0, 0.35)',
      },
      onRedGray: {
        light: 'rgba(0, 0, 0, 0.17)',
        medium: 'rgba(0, 0, 0, 0.35)',
      },
      onBlack: {
        lightest: 'rgba(255, 255, 255, 0.8)',
        light: 'rgba(255, 255, 255, 0.6)',
        medium: 'rgba(255, 255, 255, 0.4)',
        dark: 'rgba(255, 255, 255, 0.3)',
      },
    },
  },

  font: {
    sans: "'ABC Diatype', sans-serif",
  },

  card: {
    normal: {
      paddingX: [1, 1, 1, 1.5],
      paddingTop: [0.75, 0.75, 0.75, 1.25],
      paddingBottom: [1, 1, 1, 1.5],
      borderRadius: 0.75,
    },
    large: {
      paddingX: [1, 1.25, 1.25, 2],
      paddingTop: [0.75, 1.125, 1.125, 1.75],
      paddingBottom: [1, 1.25, 1.25, 2.25],
      borderRadius: 0.75,
    },
  },

  footer: {
    height: 320, // px
  },

  page: {
    borderRadius: 2,
    paddingY: [0.75, 1.5, 2.5, 4],
    paddingYWithBorderRadius: [2.75, 3.5, 4.5, 6],
  },
};
