import DiatypeWoff1 from '@/assets/fonts/ABCDiatype-Regular.woff';
import DiatypeWoff2 from '@/assets/fonts/ABCDiatype-Regular.woff2';

export default `
  @font-face {
    font-family: 'ABC Diatype';
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    src: url(${DiatypeWoff2}) format('woff2'),
      url(${DiatypeWoff1}) format('woff');
  }
`;
