import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import Img from 'gatsby-image';

import { getImageSizes } from '@/utils/dataTransformation';

const BackgroundImage = styled(Img)`
  z-index: 0;
  position: absolute !important;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  max-width: none !important;

  > div {
    display: none !important;
  }
`;

export default ({ image, columnWidth = 1, ...props }) => {
  // fluid image with custom srcset sizes
  const imageProps = {
    fluid: {
      ...image.asset.fluid,
      sizes: getImageSizes(columnWidth),
    },
  };

  return <BackgroundImage {...imageProps} {...props} />;
};

export const query = graphql`
  fragment backgroundImageData on SanityImage {
    asset {
      fluid(maxWidth: 2400) {
        ...GatsbySanityImageFluid_withWebp
      }
    }
  }
`;
