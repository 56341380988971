import React from 'react';
import styled from 'styled-components';
import { animated } from 'react-spring';

import theme from '@/styles/theme';
import { media, scale } from '@/styles/utils';

const Container = styled.div`
  z-index: 100;
  position: fixed;
  bottom: ${scale(1.5)};
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.12));
  pointer-events: none;

  ${media.md`
    bottom: ${scale(2)};
  `};
`;

const Bar = styled(animated.div)`
  height: ${scale(2.75)};
  border-radius: ${scale(1.5)};
  overflow: hidden;
  background: ${theme.color.whiteTransparent};
  pointer-events: all;

  ${media.md`
    height: ${scale(3)};
  `};

  /* IE fallback for filter drop-shadow */
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.12);
  }
`;

export default props => {
  return (
    <Container>
      <Bar {...props} />
    </Container>
  );
};
