import { css } from 'styled-components';

import { media, rem } from '@/styles/utils';

const reset = css`
  font-weight: normal;
  text-decoration: none;

  a {
    color: inherit;
    text-decoration: none;
  }
`;

export const h1 = css`
  ${reset}
  font-size: ${rem(56)};
  line-height: ${rem(56)};
  letter-spacing: -0.02em;

  ${media.md`
    font-size: ${rem(80)};
    line-height: ${rem(80)};
  `}

  ${media.lg`
    font-size: ${rem(96)};
    line-height: ${rem(96)};
  `}

  ${media.xl`
    font-size: ${rem(120)};
    line-height: ${rem(120)};
  `}
`;

export const h2 = css`
  ${reset}
  font-size: ${rem(40)};
    line-height: ${rem(44)};
  letter-spacing: -0.03em;

  ${media.md`
    font-size: ${rem(48)};
    line-height: ${rem(48)};
  `}

  ${media.lg`
    font-size: ${rem(68)};
    line-height: ${rem(68)};
  `}

  ${media.xl`
    font-size: ${rem(84)};
    line-height: ${rem(84)};
  `}
`;

export const h3 = css`
  ${reset}
  font-size: ${rem(28)};
  line-height: ${rem(32)};
  letter-spacing: -0.02em;

  ${media.lg`
    font-size: ${rem(36)};
    line-height: ${rem(40)};
  `}
`;

export const h4 = css`
  ${reset}
  font-size: ${rem(24)};
  line-height: ${rem(28)};
  letter-spacing: -0.02em;

  ${media.lg`
    font-size: ${rem(30)};
    line-height: ${rem(36)};
  `}
`;

export const pXLarge = css`
  ${reset}
  font-size: ${rem(20)};
  line-height: ${rem(23)};
  letter-spacing: -0.02em;

  ${media.md`
    font-size: ${rem(22)};
    line-height: ${rem(26)};
  `}

  ${media.lg`
    font-size: ${rem(25)};
    line-height: ${rem(28)};
  `}
`;

export const pLarge = css`
  ${reset}
  font-size: ${rem(17)};
  line-height: ${rem(20)};
  letter-spacing: -0.015em;

  ${media.md`
    font-size: ${rem(18)};
    line-height: ${rem(22)};
  `}

  ${media.lg`
    font-size: ${rem(22)};
    line-height: ${rem(26)};
  `}
`;

export const p = css`
  ${reset}
  font-size: ${rem(16)};
  line-height: ${rem(18)};
  letter-spacing: -0.015em;

  ${media.lg`
    font-size: ${rem(18)};
    line-height: ${rem(21)};
  `}
`;

export const pSmall = css`
  ${reset}
  font-size: ${rem(15)};
  line-height: ${rem(19)};
`;

export const label = css`
  ${reset}
  font-size: ${rem(10)};
  line-height: ${rem(14)};
  text-transform: uppercase;

  ${media.lg`
    font-size: ${rem(12)};
    line-height: ${rem(16)};
  `}
`;
