import { createGlobalStyle } from 'styled-components';
import styledNormalize from 'styled-normalize';

import theme from '@/styles/theme';

export default createGlobalStyle`
  ${styledNormalize}

  html {
    min-height: 100%;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    font-family: ${theme.font.sans};
    font-size: 16px;
    font-weight: normal;
    color: ${theme.color.black};
    background: ${theme.color.offBlack};
    scrollbar-color: dark; /* future-proofing */
  }

  #___gatsby {
    background: ${theme.color.offBlack};
  }

  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    box-sizing: border-box;
    margin: 0;
    padding: 0;

    ::-moz-selection {
      background: ${theme.color.gray.onWhite.light};
      color: ${theme.color.black};
    }
    ::selection {
      background: ${theme.color.gray.onWhite.light};
      color: ${theme.color.black};
    }
  }
`;
