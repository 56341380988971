import React from 'react';

import { FooterRevealContextProvider } from '@/context/FooterRevealContext';

import Head from '@/components/Head';
import Navigation from '@/components/Navigation';
import Footer from '@/components/Footer';
import PageTransition from '@/components/PageTransition';
import { SvgMasks } from '@/components/Asset';
import PageWrapper from './PageWrapper';

import transition from './transition';

import GlobalStyles from '@/styles/global';

export default ({ children, location }) => {
  return (
    <>
      <Head />

      <GlobalStyles />

      <FooterRevealContextProvider>
        <PageWrapper>
          <PageTransition.Provider location={location} {...transition}>
            <PageTransition.Views>{children}</PageTransition.Views>

            <Navigation />
          </PageTransition.Provider>
        </PageWrapper>

        <Footer />
      </FooterRevealContextProvider>

      <SvgMasks />
    </>
  );
};
