import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import Text from '@/atoms/Text';

import theme from '@/styles/theme';
import { media, scale } from '@/styles/utils';

const Item = styled(Text).attrs({ t: 0 })`
  list-style: none;

  :last-child {
    margin-right: 0;
  }
`;

const ItemLink = styled(({ isActive, innerRef, ...props }) => (
  <Link ref={innerRef} {...props} />
))`
  display: flex;
  align-items: center;
  padding: 0 ${scale(0.75)};
  height: ${scale(2.25)};
  border-radius: ${scale(1.25)};
  transition: color 0.1s ease-in-out;
  user-select: none;
  -webkit-tap-highlight-color: transparent;

  ${media.md`
    padding: 0 ${scale(1)};
    height: ${scale(2.5)};
    border-radius: ${scale(1.25)};
  `}

  && {
    color: ${props => (props.isActive ? theme.color.white : theme.color.black)};

    :hover,
    :focus,
    :active {
      color: ${props =>
        props.isActive ? theme.color.white : theme.color.gray.onWhite.dark};
    }
  }

  span {
    position: relative;
    top: 1px;
    left: 1px;
  }
`;

export default ({ title, path, innerRef, isActive, ...props }) => (
  <Item as="li" mr={0.25} {...props}>
    <ItemLink innerRef={innerRef} to={path} isActive={isActive}>
      <span>{title}</span>
    </ItemLink>
  </Item>
);
