const reduce = (state, action) => {
  if (action.type === 'UPDATE_LOCATION') {
    return {
      ...state,
      hasEntered: false,
      currentLocation: {
        ...action.location,
      },
      prevLocation: {
        ...state.currentLocation,
      },
      isTransitioningToOverlay: action.isOverlay,
    };
  }

  if (action.type === 'ADD_VIEW') {
    const nextView = { view: action.view, isOverlay: action.isOverlay };

    // if this is the initial view being mounted, we don't add it to the queue
    if (state.views.length === 0) {
      return {
        ...state,
        views: [nextView],
        enterQueue: [],
        hasEnterStarted: false,
        hasEntered: true,
      };
    }

    // if there's currently an active overlay, or if an overlay is still being animated in, we should close it
    if (
      (state.isOverlayActive ||
        (state.prevLocation &&
          state.prevLocation.state &&
          state.prevLocation.state.asOverlay)) &&
      !nextView.isOverlay
    ) {
      return {
        ...state,
        enterQueue: [],
        closeOverlay: true,
      };
    }

    // if we haven't started the enter animation we replace the queue
    if (!state.hasEnterStarted) {
      return {
        ...state,
        enterQueue: [nextView],
      };
    }

    // otherwise we just add it to the existing views
    return {
      ...state,
      views: [...state.views, nextView],
    };
  }

  if (action.type === 'START_ENTER_TRANSITION') {
    return {
      ...state,
      views: [...state.views, ...state.enterQueue],
      enterQueue: [],
      hasEnterStarted: true,
    };
  }

  if (action.type === 'HAS_ENTERED') {
    const finalView = state.views[state.views.length - 1];

    // check if this is the last view in the array
    if (
      state.enterQueue.length === 0 &&
      action.locationKey === finalView.view.props.location.key
    ) {
      // if the final view is an overlay
      if (finalView.isOverlay) {
        if (state.closeOverlay) {
          // we're closing the overlay – we should only keep the underlying view
          return {
            ...state,
            views: state.views.slice(-2, -1),
            enterQueue: [],
            hasEnterStarted: false,
            hasEntered: true,
            isOverlayActive: false,
            closeOverlay: false,
            resetScrollPosition: false,
          };
        }

        // we're opening the overlay – we should keep the underlying view and the overlay
        return {
          ...state,
          views: state.views.slice(-2),
          enterQueue: [],
          hasEnterStarted: false,
          hasEntered: true,
          isOverlayActive: true,
          closeOverlay: false,
          resetScrollPosition: false,
        };
      }

      // otherwise just reset everything
      return {
        ...state,
        views: state.views.slice(-1),
        enterQueue: [],
        hasEnterStarted: false,
        hasEntered: true,
        resetScrollPosition: true,
      };
    }
  }

  return state;
};

export default (state, action) => {
  const nextState = reduce(state, action);

  return nextState;
};
