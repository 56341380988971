import React, { useEffect, useRef } from 'react';

import { useTransitionStore } from './Provider';
import useLockBodyScroll from '@/hooks/useLockBodyScroll';

import View from './View';

export default ({ children }) => {
  const [
    {
      enterDelay,
      currentLocation,
      views,
      enterQueue,
      isOverlayActive,
      closeOverlay,
      hasEnterStarted,
      hasEntered,
      resetScrollPosition,
    },
    dispatch,
  ] = useTransitionStore();

  const enterTimeout = useRef();
  const isInitialMount = views.length === 0;
  const isTransitioning = views.length + enterQueue.length > 1;

  // lock body scroll if overlay is active
  useLockBodyScroll(isOverlayActive);

  useEffect(() => {
    if (currentLocation.key) {
      const asOverlay =
        currentLocation.state &&
        typeof currentLocation.state.asOverlay !== 'undefined' &&
        currentLocation.state.asOverlay;

      dispatch({
        type: 'ADD_VIEW',
        view: children,
        isOverlay: asOverlay,
      });
    }
  }, [currentLocation.key]);

  useEffect(() => {
    // Start the enter transition after the enter delay has passed
    if (
      !isInitialMount &&
      !hasEnterStarted &&
      typeof enterTimeout.current === 'undefined'
    ) {
      enterTimeout.current = window.setTimeout(() => {
        dispatch({ type: 'START_ENTER_TRANSITION' });
        enterTimeout.current = undefined;
      }, enterDelay);
    }

    return () =>
      typeof enterTimeout.current !== 'undefined' &&
      window.clearTimeout(enterTimeout.current);
  }, [currentLocation.key]);

  useEffect(() => {
    if (hasEntered && !isOverlayActive && resetScrollPosition) {
      window.scrollTo(0, 0);
    }
  }, [hasEntered]);

  return (
    <>
      {isInitialMount ? (
        <View
          key="initialmount"
          view={children}
          action="MOUNT"
          isOverlay={false}
        />
      ) : (
        views.map(({ view, isOverlay }, i) => {
          let action = 'MOUNT';

          if (closeOverlay) {
            action = isOverlay ? 'OVERLAY_LEAVE' : 'OVERLAY_RESUME';
          } else if (isTransitioning) {
            action = i === 0 ? 'LEAVE' : 'ENTER';
          }

          return (
            <View
              key={view.props.location.key}
              view={view}
              action={action}
              isOverlay={isOverlay}
            />
          );
        })
      )}
    </>
  );
};
