import React, { createContext, useContext, useEffect, useReducer } from 'react';

import reducer from './reducer';

export const TransitionContext = createContext();

export const TransitionProvider = ({
  location = {},
  enterDelay = 500,
  enter = { opacity: 0, config: 'stiff' },
  usual = { opacity: 1, config: 'stiff' },
  leave = { opacity: 0, config: 'stiff' },
  children,
}) => {
  const [state, dispatch] = useReducer(reducer, {
    currentLocation: { key: undefined },
    prevLocation: null,
    views: [],
    enterQueue: [],
    isTransitioningToOverlay: false,
    isOverlayActive: false,
    closeOverlay: false,
    enterDelay,
    enter,
    usual,
    leave,
    hasEnterStarted: false,
    hasEntered: false,
    resetScrollPosition: true,
  });

  useEffect(() => {
    const asOverlay =
      location.state &&
      typeof location.state.asOverlay !== 'undefined' &&
      location.state.asOverlay;

    dispatch({
      type: 'UPDATE_LOCATION',
      location,
      isOverlay: asOverlay,
    });
  }, [location.pathname]);

  return (
    <TransitionContext.Provider value={[state, dispatch]}>
      {children}
    </TransitionContext.Provider>
  );
};

export const useTransitionStore = () => useContext(TransitionContext);
