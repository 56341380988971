import { useEffect } from 'react';

export default onResize => {
  useEffect(() => {
    let timeout;
    const delay = 150;

    const handleResize = () => {
      window.clearTimeout(timeout);
      timeout = setTimeout(onResize, delay);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.clearTimeout(timeout);
    };
  }, [onResize]);
};
