export default {
  enterDelay: 200,
  enter: {
    opacity: 1,
    transform: 'translate3d(0,110vh,0) scale(1)',
    config: {
      mass: 1,
      tension: 200,
      friction: 35,
      clamp: true,
    },
  },
  usual: {
    opacity: 1,
    transform: 'translate3d(0,0vh,0) scale(1)',
  },
  leave: {
    opacity: 0.8,
    transform: 'translate3d(0,0vh,0) scale(0.9)',
    config: {
      mass: 1,
      tension: 280,
      friction: 35,
      clamp: true,
    },
  },
};
