export const onClientEntry = async () => {
  // polyfill intersetion observer
  if (typeof window.IntersectionObserver === 'undefined') {
    await import('intersection-observer');
  }
};

// disable scroll-to-top on route change
export const shouldUpdateScroll = () => {
  return false;
};
